<template>
  <div class="box">
    <audio
      id="audio"
      class="audio"
      autoplay="autoplay"
      loop="loop"
      preload="auto"
      controls="controls"
      src="http://cdn.topartsintermational.com/newYear.mp3"
    ></audio>
    <div class="limit-box">
      <el-button
        type="danger"
        class="index"
        @click="() => this.$router.push('/')"
        >НАСЛОВНА СТРАНИЦА</el-button
      >
      <el-button
        class="intro-btn"
        type="danger"
        @click="() => (this.introVisible = true)"
        >Увод</el-button
      >
      <div class="door" v-show="visible">
        <img src="http://cdn.topartsintermational.com/door.gif" alt="" />
        <video
          :src="src"
          controls
          class="endVideo"
          v-show="showVideo"
          autoplay
          muted
        ></video>
      </div>
      <div class="door" v-show="!visible">
        <img src="http://cdn.topartsintermational.com/door.png" />
        <div class="door-left door-part">
          <!-- 左门 -->
          <draggable
            :forceFallback="true"
            :list="leftBox"
            :options="{ group: 'leftDoor' }"
            @start="leftStart"
            @end="leftEnd"
            class="leftDrag"
            style="height: 150px"
            :move="onLeftMove"
          >
            <img
              v-for="element in leftBox"
              :key="element.id"
              :src="element.url"
              class="door-box"
            />
          </draggable>
        </div>
        <div class="door-right door-part">
          <!-- 右门 -->
          <draggable
            :forceFallback="true"
            :list="rightBox"
            :options="{ group: 'rightDoor' }"
            @start="rightStart"
            @end="rightEnd"
            class="rightDrag"
            style="height: 150px"
            :move="onRightMove"
          >
            <img
              v-for="element in rightBox"
              :key="element.id"
              class="door-box"
              :src="element.url"
            />
          </draggable>
        </div>
      </div>
      <div class="list-box">
        <div class="left-list list">
          <h3>Божанство са леве стране врата</h3>
          <draggable
            :forceFallback="true"
            :list="leftList"
            :options="{
              group: { name: left, pull: 'clone' },
              filter: '.undraggable',
              sort: false,
            }"
            @end="onLeftEnd"
            :move="onLeftMove"
            class="dragArea"
          >
            <img
              v-show="!element.flag"
              v-for="element in leftList"
              :key="element.id"
              :class="{ undraggable: element.flag }"
              :src="element.url"
              class="left-item list-img"
              width="100px"
              height="150px"
            />
          </draggable>
        </div>
        <div class="right-list list">
          <h3>Божанство са десне стране врата</h3>
          <draggable
            :forceFallback="true"
            :list="rightList"
            :options="{
              group: { name: right, pull: 'clone' },
              filter: '.undraggable',
              sort: false,
            }"
            @end="onRightEnd"
            :move="onRightMove"
            class="dragArea"
          >
            <img
              v-show="!element.flag"
              v-for="element in rightList"
              :key="element.id"
              :class="{ undraggable: element.flag }"
              :src="element.url"
              class="right-item list-img"
              width="100px"
              height="150px"
            />
          </draggable>
        </div>
      </div>
    </div>
    <div class="cont">
      <div class="icon">
        <a
          href="http://twitter.com/share?url=https://cccsydney.org"
          target="_blank"
        >
          <img src="../../assets/t.png" width="30px" height="30px" />
        </a>
        <a
          href="http://www.facebook.com/sharer.php?u=https://cccsydney.org"
          target="_blank"
        >
          <img src="../../assets/f.png" width="30px" height="30px" />
        </a>
      </div>
      <div class="cont-menu">
        <el-row>
          <el-col :span="5">{{ $t("message.footer.left.title") }}</el-col>
          <el-col :span="19">
            <p>{{ $t("message.footer.left.item1") }}</p>
            <p>{{ $t("message.footer.left.item2") }}</p>
          </el-col>
        </el-row>
      </div>
      <div class="cont-menu">
        <el-row>
          <el-col :span="4">{{ $t("message.footer.right.title") }}</el-col>
          <el-col :span="20"
            ><p>{{ $t("message.footer.right.item") }}</p></el-col
          >
        </el-row>
      </div>
    </div>
    <!-- 模态框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="1200px"
      :show-close="false"
      custom-class="dialog"
      destroy-on-close
      @close="onClose"
    >
      <video
        src="http://cdn.topartsintermational.com/game-fir-s.mp4"
        controls
        width="1200px"
        style="display: block"
      ></video>
    </el-dialog>
    <!-- <div class="qw-dialog">
      <el-dialog
        :visible.sync="visible"
        width="1000px"
        :show-close="false"
        custom-class="dialog"
        destroy-on-close
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
        <video
          src="http://cdn.topartsintermational.com/flower.mp4"
          autoplay
          loop
          width="1200px"
          style="display: block"
          @click="handleJump"
        />
      </el-dialog>
    </div> -->
    <!-- 活动介绍模态框 -->
    <el-dialog
      :visible.sync="introVisible"
      width="1200px"
      custom-class="dialog"
      destroy-on-close
    >
      <div class="introBox">
        <h1>Занимљиве новогодишње слике – Чувар прага</h1>
        <p>
          Како би се створила снажна празнична атмосфера и изазвало масовније
          интерактивно учешће, новогодишње слике на дрвеним плочама из
          Фенгсијанга (карактеристичне за провинцију Шанси) привлаче публику да
          постане део кинеске Нове године. Обичај качења Чувара прага на врата
          дома током Фестивала пролећа одржао се хиљадама година – у исто време
          се тиме с колена на колено преноси традиционална кинеска култура и
          подиже празнична атмосфера, људима шаљу најлепше жеље, изражава тежња
          ка миру и срећи. Овај догађај заснива се на традиционалним кинеским
          новогодишњим обичајима – тема је Чувар прага који доноси срећу. Ако
          Чувара прага залепите тако да заузме одговарајући положај, он тиме
          отвара врата лепим стварима. У исто време, овај радостан догађај дели
          се и преко рачунара или мобилних телефона – путем друштвених мрежа,
          дели се радост и моли за срећу, дуговечност и здравље чланова
          породице.
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { shuffle } from '../../utils/utils'
export default {
  name: 'Game',
  components: { draggable },
  data () {
    return {
      left: 'leftDoor',
      right: 'rightDoor',
      visible: false,
      dialogVisible: true,
      introVisible: false,
      showVideo: false,
      src: 'http://cdn.topartsintermational.com/end1.mp4',
      leftBox: [],
      leftList: [
        {
          id: 1,
          url: 'http://cdn.topartsintermational.com/door-left1.png',
          code: 1001,
        },
        {
          id: 2,
          url: 'http://cdn.topartsintermational.com/door-left2.png',
          code: 1002,
        },
        {
          id: 3,
          url: 'http://cdn.topartsintermational.com/door-left3.png',
          code: 1003,
        },
        {
          id: 4,
          url: 'http://cdn.topartsintermational.com/door-left4.png',
          code: 1004,
        },
        {
          id: 5,
          url: 'http://cdn.topartsintermational.com/door-left5.png',
          code: 1005,
        },
        {
          id: 6,
          url: 'http://cdn.topartsintermational.com/door-left6.png',
          code: 1006,
        },
        {
          id: 7,
          url: 'http://cdn.topartsintermational.com/door-left7.png',
          code: 1007,
        },
        {
          id: 8,
          url: 'http://cdn.topartsintermational.com/door-left8.png',
          code: 1008,
        },
        {
          id: 9,
          url: 'http://cdn.topartsintermational.com/door-left9.png',
          code: 1009,
        },
        {
          id: 10,
          url: 'http://cdn.topartsintermational.com/door-left10.png',
          code: 10010,
        },
        {
          id: 11,
          url: 'http://cdn.topartsintermational.com/door-left11.png',
          code: 10011,
        },
        {
          id: 12,
          url: 'http://cdn.topartsintermational.com/door-left12.png',
          code: 10012,
        },
        {
          id: 13,
          url: 'http://cdn.topartsintermational.com/door-left13.png',
          code: 10013,
        }
      ],
      rightBox: [],
      rightList: [
        {
          id: 1,
          url: 'http://cdn.topartsintermational.com/door-right1.png',
          code: 1001,
        },
        {
          id: 2,
          url: 'http://cdn.topartsintermational.com/door-right2.png',
          code: 1002,
        },
        {
          id: 3,
          url: 'http://cdn.topartsintermational.com/door-right3.png',
          code: 1003,
        },
        {
          id: 4,
          url: 'http://cdn.topartsintermational.com/door-right4.png',
          code: 1004,
        },
        {
          id: 5,
          url: 'http://cdn.topartsintermational.com/door-right5.png',
          code: 1005,
        },
        {
          id: 6,
          url: 'http://cdn.topartsintermational.com/door-right6.png',
          code: 1006,
        },
        {
          id: 7,
          url: 'http://cdn.topartsintermational.com/door-right7.png',
          code: 1007,
        },
        {
          id: 8,
          url: 'http://cdn.topartsintermational.com/door-right8.png',
          code: 1008,
        },
        {
          id: 9,
          url: 'http://cdn.topartsintermational.com/door-right9.png',
          code: 1009,
        },
        {
          id: 10,
          url: 'http://cdn.topartsintermational.com/door-right10.png',
          code: 10010,
        },
        {
          id: 11,
          url: 'http://cdn.topartsintermational.com/door-right11.png',
          code: 10011,
        },
        {
          id: 12,
          url: 'http://cdn.topartsintermational.com/door-right12.png',
          code: 10012,
        },
        {
          id: 13,
          url: 'http://cdn.topartsintermational.com/door-right13.png',
          code: 10013,
        }
      ],
    }
  },
  mounted () {
    this.leftList = shuffle(this.leftList)
    this.rightList = shuffle(this.rightList)
    const num = Math.ceil(Math.random() * 14)
    this.src = 'http://cdn.topartsintermational.com/end-' + num + '.mp4'
  },
  watch: {
    leftBox: function (val) {
      if (val[0] && val[0].flag && this.rightBox.length === 1) {
        const left = val[0]
        const right = this.rightBox[0]
        if (left.code === right.code) {
          this.visible = true
          // const audio = document.getElementById('audio')
          // audio.pause()
          const video = document.getElementsByClassName('endVideo')
          // video[0].muted = false
          setTimeout(() => {
            this.showVideo = true
            video[0].play()
          }, 1000)
        } else {
          this.$message.error('Погрешно, меч је пропао')
        }
      }
    },
    rightBox: function (val) {
      if (val[0] && val[0].flag && this.leftBox.length === 1) {
        const left = this.leftBox[0]
        const right = val[0]
        if (left.code === right.code) {
          this.visible = true
          // const audio = document.getElementById('audio')
          // audio.pause()
          const video = document.getElementsByClassName('endVideo')
          // video[0].muted = false
          setTimeout(() => {
            this.showVideo = true
            video[0].play()
          }, 1000)
        } else {
          this.$message.error('Погрешно, меч је пропао')
        }
      }
    },
    deep: true,
  },
  methods: {
    onClose () {
      const audio = document.getElementById('audio')
      audio.play()
    },
    // 点击烟花跳转
    handleJump () {
      const num = Math.floor(Math.random() * 6)
      this.$router.push(`/share/${num}`)
    },
    // 列表拖拽结束，此处可以计算index，删除leftlist指定项
    onLeftEnd (ev) {
      if (ev.to.className === 'leftDrag') {
        this.$set(this.leftList[ev.oldIndex], 'flag', true)
      }
    },
    onRightEnd (ev) {
      if (ev.to.className === 'rightDrag') {
        this.$set(this.rightList[ev.oldIndex], 'flag', true)
      }
    },
    leftStart () {
      this.left = ''
    },
    leftEnd () {
      this.left = 'leftDoor'
    },
    rightStart () {
      this.right = ''
    },
    rightEnd () {
      this.right = 'rightDoor'
    },
    // leftDel (index, id) {
    //   this.leftBox.splice(index, 1)
    //   let q = this.leftList.find((value) => {
    //     return value.id === id
    //   })
    //   this.$set(q, 'flag', false)
    // },
    // rightDel (index, id) {
    //   this.rightBox.splice(index, 1)
    //   let q = this.rightList.find((value) => {
    //     return value.id === id
    //   })
    //   this.$set(q, 'flag', false)
    // },
    // 此处进行列表整合
    onLeftMove () {
      if (this.leftBox.length === 1) {
        const oldId = this.leftBox[0].id
        this.leftBox.pop()
        let q = this.leftList.find((item) => {
          return item.id === oldId
        })
        this.$delete(q, 'flag')
      }
    },
    onRightMove () {
      if (this.rightBox.length === 1) {
        const oldId = this.rightBox[0].id
        this.rightBox.pop()
        let q = this.rightList.find((item) => {
          return item.id === oldId
        })
        this.$delete(q, 'flag')
      }
    }
  }
}
</script>

<style scoped lang="less">
h1 p {
  margin: 0;
  padding: 0;
}
.box {
  width: 100%;
  min-width: 1240px;
  min-height: 100vh;
  background: url("http://cdn.topartsintermational.com/gameBg.jpg") no-repeat;
  background-size: 100% 100%;

  .limit-box {
    width: 1200px;
    position: relative;
    margin: auto;
  }
  .audio {
    display: none;
  }
  .intro-btn {
    position: absolute;
    top: 10%;
    right: 0;
    color: #fff;
    font-size: 32px;
    background-color: #ca3540;
    z-index: 1;
  }
  .index {
    position: absolute;
    top: 10%;
    left: 0;
    color: #fff;
    font-size: 32px;
    background-color: #ca3540;
    z-index: 1;
  }
  .door {
    display: flex;
    position: relative;
    padding-top: 96px;
    .endVideo {
      width: 622px;
      height: 432px;
      position: absolute;
      top: 376px;
      left: 290px;
      background: #000;
    }
    img {
      display: block;
      margin: auto;
    }
    .door-part {
      width: 100px;
      height: 150px;
      border: 1px solid #fff;
      background-color: red;
      position: absolute;
      .door-box {
        width: 100px;
        height: 150px;
      }
    }
    .door-left {
      top: 56%;
      left: 38%;
    }
    .door-right {
      top: 56%;
      right: 38%;
    }
  }
  .list-box {
    width: 1200px;
    display: flex;
    justify-content: space-around;
    background-color: #5c0108;
    color: #fff;
    padding-bottom: 48px;
    margin: 48px auto 0;
    .list {
      width: 48%;
      .dragArea {
        width: 100%;
        height: 150px;
        display: inline-block;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 3px;
          background: rgb(248 212 180);
          -webkit-box-shadow: inset 0 0 5px rgb(248 212 180);
        }
        /* 滚动条滑块 */
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background: #000;
          -webkit-box-shadow: inset 0 0 10px rgb(234 181 131);
        }

        .list-img {
          cursor: pointer;
          display: inline-block;
        }
      }
    }
  }
  .introBox {
    min-height: 500px;
    background-color: rgba(92, 1, 8, 0.8);
    h1 {
      color: #fff;
      margin: 0;
      text-align: center;
      line-height: 3;
    }
    p {
      color: #fff;
      margin: 0;
      text-indent: 1em;
      line-height: 2;
      font-size: 18px;
      padding: 0 8px;
    }
  }
  .cont {
    width: 1200px;
    margin: 16px auto 0;
    padding-bottom: 32px;
    display: flex;
    .cont-menu {
      width: 45%;
      color: #fff;
      padding-left: 16px;
      p {
        margin: 0;
        padding: 0;
        line-height: 24px;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

// .list-complete-item {
//   cursor: pointer;
//   position: relative;
//   font-size: 14px;
//   padding: 5px 12px;
//   margin-right: 20px;
//   border: 1px solid #bfcbd9;
//   transition: all 1s;
// }

// .list-complete-item.sortable-chosen {
//   background: #4ab7bd;
// }

// .list-complete-item.sortable-ghost {
//   background: #30b08f;
// }
.undraggable {
  background-color: red;
}

// .list-complete-enter,
// .list-complete-leave-active {
//   opacity: 0;
// }
</style>